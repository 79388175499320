export enum DialogType {
  Availability,
  CancelBooking,
  Checkout,
  Editor,
  GooglePlacesImport,
  Map,
  NewExperience,
  ConfirmTrip,
}
